<template>
    <div class="gray-bg order-confirm" v-htmltit data-title="确认订单">
        <go-back-btn></go-back-btn>
        <go-cart-btn></go-cart-btn>
        <pay-type  
            :propShow="propShowPayType"
            :totalMoney="total_pay_money"
            @handleClickPropShow="handleClickPropShowPayType"
            @handleChangePayType="handleChangePayType"></pay-type>
        <template>
            <div class="order-confirm-address-box" v-if="address.id">
                <div class="address-icon">
                    <van-icon name="location" />
                </div>
                <div class="address-con">
                    <div class="address-user-info">
                        <div class="address-user-name">{{address.consignee}}</div>
                        <div class="address-user-phone">{{address.phone}}</div>
                    </div>
                    <div class="address-detail">{{address.area}} {{address.address}}</div>
                </div>
                <div class="address-update-btn" @click="handleClickUpdateAddress">
                    <div>修改地址</div> 
                     <van-icon name="arrow" />
                </div>
            </div>
            <!-- <div class="payment-type" @click="propShowPayType = !propShowPayType">
                <div>支付方式</div>
                <div class="payment-type-con">
                    <div>{{paymentType.name}}</div>
                </div>
                <van-icon name="arrow" />
            </div> -->
            <div class="order-confirm-list">
                <div  v-for="(item,index) in orderConfirmList" :key="index">
                    <div class="order-confirm-box" v-if="item.goods_type < '2'">
                        <div class="order-confirm-item">
                            <div class="order-confirm-goodinfo">
                                <div class="order-confirm-img">
                                    <van-image fit="contain"
                                        class="img-box"
                                        :src="getImgSizeJpg(item.img,4.8,3.6)">
                                        <template v-slot:error>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                        <template v-slot:loading>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                    </van-image>
                                </div>
                                <div class="order-confirm-good">
                                    <div class="order-confirm-name">{{item.name}}</div>
                                    <div class="order-confirm-sku" v-if="item.standardstr">{{item.standardstr}}</div>
                                </div>
                                <div class="order-confirm-right">
                                    <div class="order-confirm-price">{{Number(item.price).toFixed(2)}}</div>
                                    <div class="order-confirm-count">X{{item.total_quantity}}</div>
                                    <div class="order-confirm-priceTotal">{{Number(item.price *item.total_quantity).toFixed(2)}}</div>
                                </div>
                            </div>
                            <div class="order-note">
                                <van-field style="padding:0;" v-model="item.orderRemark"  placeholder="请输入备注" />
                            </div>
                        </div>
                    </div>
                    <div class="order-confirm-box order-confirm-box2"  v-if="item.goods_type == '2'">
                        <div class="order-confirm-item">
                            <div class="order-confirm-goodinfo">
                                <div class="order-confirm-img">
                                    <van-image fit="contain"
                                        class="img-box"
                                        :src="getImgSizeJpg(item.img,4.8,3.6)">
                                        <template v-slot:error>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                        <template v-slot:loading>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                    </van-image>
                                </div>
                                <div class="order-confirm-good">
                                    <div class="order-confirm-name">{{item.name}}</div>
                                    <div class="order-confirm-sku" v-if="item.standardstr">{{item.standardstr}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="order-confirm-sku-box" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                            <div class="order-confirm-sku-list">
                                <div class="order-confirm-sku-item" 
                                    v-for="childItem in item.glass_myopia_atrr.attr_val" 
                                    :key="childItem.id">
                                    <div class="order-confirm-sku-item-item">
                                        <div class="order-confirm-sku-item-left">
                                            <div>[{{childItem.myopia}},{{childItem.astigmatism}}]</div>
                                            <div  class="order-confirm-price">{{Number(childItem.price).toFixed(2)}}<span style="font-size:0.6rem">/{{item.unit}}</span></div>
                                        </div>
                                        <div class="order-confirm-sku-item-right">
                                            <div style="color:#969799;font-size:0.7rem">x{{childItem.total_quantity}}</div>
                                            <div class="order-confirm-price">{{Number(childItem.price *childItem.total_quantity).toFixed(2)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="order-note">
                                <van-field style="padding:0.4rem;" v-model="item.orderRemark"  placeholder="请输入备注" />
                            </div>
                        </div>
                    </div>
                    <div class="order-confirm-box order-confirm-box2"  v-if="item.goods_type == '3'">
                        <div class="order-confirm-item">
                            <div class="order-confirm-goodinfo">
                                <div class="order-confirm-img">
                                    <van-image fit="contain"
                                        class="img-box"
                                        :src="getImgSizeJpg(item.img,4.8,3.6)">
                                        <template v-slot:error>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                        <template v-slot:loading>
                                            <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                        </template>
                                    </van-image>
                                </div>
                                <div class="order-confirm-good">
                                    <div class="order-confirm-name">{{item.name}}</div>
                                    <div class="order-confirm-sku" v-if="item.standardstr">{{item.standardstr}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="order-confirm-sku-box" v-if="item.glass_myopia_atrr.attr_val.length > 0">
                            <div class="order-confirm-sku-list">
                                <div class="order-confirm-sku-item" 
                                    v-for="childItem in item.glass_myopia_atrr.attr_val" 
                                    :key="childItem.id">
                                    <div class="order-confirm-sku-item-item">
                                        <div class="order-confirm-sku-item-left">
                                            <div>
                                                <span v-if="childItem.eyes_type !== '0'">{{ childItem.eyes_type | eyesTypeFilter }},</span>
                                                [{{childItem.myopia}},{{childItem.astigmatism}}]
                                                <span v-if="childItem.cylinder_axis">,散光轴位:{{childItem.cylinder_axis}}</span>
                                                <span v-if="childItem.pupillary_distance">,瞳距(mm):{{childItem.pupillary_distance}}</span>
                                                <span v-if="childItem.add_light">,下加光ADD:{{childItem.add_light}}</span>
                                            </div>
                                            <div  class="order-confirm-price">{{Number(childItem.price).toFixed(2)}}<span style="font-size:0.6rem">/{{item.unit}}</span></div>
                                        </div>
                                        <div class="order-confirm-sku-item-right">
                                            <div style="color:#969799;font-size:0.7rem">x{{childItem.total_quantity}}</div>
                                            <div class="order-confirm-price">{{Number(childItem.price *childItem.total_quantity).toFixed(2)}}</div>
                                        </div>
                                    </div>
                                    <!-- <div class="order-confirm-sku-item-item order-confirm-sku-item-item2">
                                        <div style="margin-right:0.4rem">处方图片</div>
                                        <div>
                                            <van-image fit="contain"
                                                class="img-box"
                                                :src="item.img">
                                                <template v-slot:error>
                                                    <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                                </template>
                                                <template v-slot:loading>
                                                    <img style="width:4.8rem;height:3.6rem" :src="defaultImg" />
                                                </template>
                                            </van-image>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="order-note">
                                <van-field style="padding:0.4rem;" v-model="item.orderRemark"  placeholder="请输入备注" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-all-note">
                <van-field label="订单备注" v-model="orderLaywords" style="padding:0 0.5rem;"  placeholder="请输入订单备注" />
            </div>
            <!-- <div class="discount-box">
                <div class="discount-item">
                    <div class="discount-type">
                        满减劵
                    </div>
                    <div class="discount-count isHas">5张</div>
                    <van-icon name="arrow" />
                </div>
                <div class="discount-item">
                    <div class="discount-type">
                        红包
                    </div>
                    <div class="discount-count notHas">暂无可用</div>
                    <van-icon name="arrow" />
                </div>
            </div> -->
             <div class="discount-box" style="margin-bottom:4rem;">
                <div class="discount-item">
                    <div class="discount-type">
                        订单总额
                    </div>
                    <div class="discount-count price">{{Number(total_money).toFixed(2)}}</div>
                </div>
                <!-- <div class="discount-item">
                    <div class="discount-type">
                        运费
                    </div>
                    <div class="discount-count price">3</div>
                    <van-icon name="arrow" />
                </div> -->
            </div>
            <div class="order-confirm-bottom">
                <div class="order-confirm-totalPrice">
                    <div>实际支付:<span class="price">￥{{Number(total_pay_money).toFixed(2)}}</span></div>
                    <!-- <div>优惠:<span class="price">￥{{total_discount_money}}</span></div> -->
                </div>
                <div class="order-confirm-btn" @click="handleClickSubmitOrderSelectPay">提交({{orderConfirmList.length}})</div>
            </div>
        </template>
    </div>
</template>
<script>
import './index.less'
import {ImagePreview} from "vant";
import goCartBtn from "@/view/Public/goCartBtn"
import goBackBtn from "@/view/Public/goBackBtn"
import payType from "@/view/Public/payType"
import {recordComputepromotion, deliveryAddress ,paytype ,recordOrderBalance ,orderCreate } from  "@/apis/api"
import {wxConfig} from  "@/libs/utils"
import eventBus from "@/libs/bus"
import defaultImg from "@/assets/image/default.png"
import bshop_global from "@/libs/global"
// 左眼 右眼
const eyesTypeMap = {
    '0': {
        value: '0',
        name: '不区分'
    },
    '1': {
        value: '1',
        name: '左眼'
    },
    '2': {
        value: '2',
        name: '右眼'
    }
}
export default {
    name:'order-confirm',
    components:{payType, goCartBtn,goBackBtn },
    data(){
        return{
            defaultImg:defaultImg,
            address:{},
            //选择的支付方式
            paymentType:{},
            paymentTypeMask:false, //选择支付方式的遮罩
            orderConfirmList:[],
            orderLaywords:"", //订单备注
            total_discount_money:0,// 优惠的价格
            total_pay_money:0,//实际支付价格
            total_money:0, // 总价
            // 传到支付方式弹窗组件的数据 start
            propShowPayType:false
            // 传到支付方式弹窗组件的数据 end
        }
    },
    destroyed(){
        this.$store.commit("delTempAddress")
    },
    filters: {
        eyesTypeFilter (state) {
            return eyesTypeMap[state].name
        }
    },
    mounted(){
        this.getOrderConfirmList();
        //判断如果是选择的地址就用选择的地址
        let tempAddress = this.$store.state.temporaryaddress.tempaddress;
        if(tempAddress != ""){
            let data = {
                id:tempAddress.id,
                area:"",
                consignee:tempAddress.name,
                phone:tempAddress.tel,
                address:tempAddress.address,
            }
            this.address = data
        }else{
             this.getDeliveryAddress();
        }
       
    },
    methods:{
        //获取商品信息
        getOrderConfirmList(){
            let params = {
                id:this.$route.query.id
            }
            let toast = this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
                loadingType: 'spinner',
            });
            recordComputepromotion(params).then(res=>{
                toast.clear();
                if(res.code == 0){
                    let data = res.res.skulist
                    data.map(item=>{
                        item.tip=""
                    })
                    this.orderConfirmList = data
                    this.getPaytype();
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //获取我的收获地址
        getDeliveryAddress(){
            deliveryAddress().then(res=>{
                if(res.code == 0){
                    let addressTemp = res.res.addresses
                    if(res.res.addresses.length != 0){
                        addressTemp.map(item=>{
                            if(item.isdefault == "1"){
                                this.address = item
                            }else{
                                this.address = addressTemp[0]
                            }
                        })
                    }
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //修改收货地址
        handleClickUpdateAddress(){
            this.$router.push({
                name:"address-list",
                query: {
                     isOrderConfirm: true
                }
            })
        },
        //获取默认支付方式
        getPaytype(){
            paytype().then(res=>{
                if(res.code== 0){
                    let paymentType = res.res.data
                    paymentType.map((item,index)=>{
                        if(item.type == 1){
                            if(localStorage.getItem('sysOfficeId') === '2d438ec112d442058dc16fc7debe8da6'){
                                item.name = "发货付款"
                            }else{
                                item.name = "货到付款"
                            }
                        }
                        if(item.type == 2){
                            item.name = "微信支付"
                        }
                        if(item.type == 3){
                            item.name = "微信转账"
                        }
                        if(item.type == 4){
                            item.name = "支付宝"
                        }
                        if(item.type == 5){
                            item.name = "余额支付"
                        }
                        if(index == 0){
                           this.paymentType = item
                        }
                    })
                    this.getOrderComputePromotion();
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //计算价格  isPay 是否创建订单
        getOrderComputePromotion(isPay){
            let data = {
                pay_type:this.paymentType.type,
                record_order_id:this.$route.query.id
            }
            recordOrderBalance(data).then(res=>{
                if(res.code == 0){
                    this.total_discount_money = res.res.total_discount_money;
                    this.total_pay_money = res.res.total_pay_money;
                    this.total_money = res.res.total_money;
                    if(isPay){
                        //选择支付方式之后 isPay为true就去支付
                        this.handleClickSubmitOrder();
                    }
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //提交订单前 判断是否有地址 选择支付方式 
        handleClickSubmitOrderSelectPay(){
            if(this.address.id){
                this.propShowPayType = true;
            }else{
                this.$router.push({
                    name:"my-address"
                })
            }
        },
        //提交订单
        handleClickSubmitOrder(){
            let skulist = [];
            this.orderConfirmList.map(item=>{
                let attrs = [];
                item.attrs.map(childItem=>{
                    let childItemList = {
                        arr_id:childItem.attr_id,
                        val:childItem.type == 1?childItem.attr_val_id:childItem.attr_val,
                        type:childItem.type,
                    }
                    attrs.push(childItemList)
                })
                if(item.goods_type == '2' || item.goods_type == '3'){
                    item.glass_myopia_atrr.attr_val.map(childItem=>{
                        let itemList = { 
                            spuId:item.spuId,
                            skuId:item.skuId,
                            orderRemark:item.orderRemark,
                            sku_measure_id:item.sku_measure_id,
                            attrs:attrs,
                            record_order_detail_id:childItem.record_order_detail_id,
                            quantity:childItem.total_quantity,
                            myopia:childItem.myopia+"|"+childItem.astigmatism
                        }
                        skulist.push(itemList)
                    })
                }else{
                    let itemList = { 
                        spuId:item.spuId,
                        skuId:item.skuId,
                        orderRemark:item.orderRemark,
                        sku_measure_id:item.sku_measure_id,
                        record_order_detail_id:item.record_order_detail_id,
                        quantity:item.total_quantity,
                        attrs:attrs
                    }
                    skulist.push(itemList)
                }
                
            })
            let data = {
                addressId: this.address.id,
                record_order_id:this.$route.query.id,
                realpay:this.total_pay_money,
                laywords:this.orderLaywords,
                paytype:this.paymentType.type,
                skulist:skulist,
                // 下边是后加的参数
                order_model:'1', // 下单方式 1：商城自主下单 2：业务员代下单 3：小程序下单4.新erp下单
                place_order_id:localStorage.getItem('customermasterId'), // 下单人id
                freight:0,
                receiving_type:'2' // 提货方式 1 自取 2 快递物流
            }
            //将data放在缓存中 以便重新支付的时候可以拿到
            localStorage.setItem("againReq",JSON.stringify(data))
            if(((data.paytype == 2) || (data.paytype == 4)) &&(Number(data.realpay)< 0.01)){
                this.$toast("实际支付金额小于0.01时，不能用微信和支付宝支付")
                return
            }
            orderCreate(data).then(res=>{
                if(res.code == 0){
                    if(this.paymentType.type == 1){
                        this.$router.replace({ name:"payment-success" })
                    }else if(this.paymentType.type == 2){
                        wxConfig(res.res.wxJsApiParam);
                        //将返回的信息放在缓存中 以便重新支付时获取
                        localStorage.setItem("againRes",JSON.stringify(res.res.wxJsApiParam))
                    }else if(this.paymentType.type == 3){
                    }else if(this.paymentType.type == 4){
                        window.location.href =  bshop_global.bShopUrl + "/#/payinfo?code=" + res.res.orderId+"&customerId=" + localStorage.getItem('customerId') +"&sysOfficeId="+ localStorage.getItem('sysOfficeId');
                    } else if(this.paymentType.type == 5){
                        this.$router.replace({ name:"payment-success"})
                    }
                }else{
                    this.$toast(res.msg)
                }
            })
        },
        //图片预览
        handleClickPreview(img){
            ImagePreview({
                images:[img],
                showIndex:false
            });
        },
         //切换支付方式弹窗组件的propShow  start
        handleClickPropShowPayType(v){
            this.propShowPayType = v;
        },
        //切换支付方式
        handleChangePayType(v){
            this.paymentType = v;
            this.getOrderComputePromotion(true) //切换支付方式要调一下
        }
        //切换支付方式弹窗组件的propShow end
    }
}
</script>